<template src="./Home.html">
   
</template>

<script>
    export default {
        name: 'Home',

        components: {
           
        },
        created() {
            window.scrollTo(0, 0);
        },
        data: () => ({
            
        }),

        methods: {

            getAndroidApp() {
                this.$http.getAndroidApp()
                    .then(res => {
                        let file = res.data.base64File;
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        let blob = new Blob([file], { type: "application/apk" });
                        let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = 'TAHSEEN.apk';
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(err => {
                        this.$store.commit("snackbar/show",
                            { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                        )
                    });
            },

            goToGoogle() {
                window.open('https://play.google.com/store/apps/details?id=ly.gov.ncdc.vac', 'blank')
            },

            goToHuawei() {
                window.open('https://appgallery.huawei.com/app/C105582783', 'blank')
            }
        }
    };
</script>

<style lang="scss" src="./Home.scss">

   
</style>
